<template>
    <div class="inline">
        <select class="w-60px" v-model="comp.hour" @change="comp.change">
            <option v-for="(h, idx) in comp.optHours" :key="idx" :value="h">{{h}}</option>
        </select>
        <select class="w-60px ml-10" v-model="comp.minute" @change="comp.change">
            <option v-for="(m, idx) in comp.optMinutes" :key="idx" :value="m">{{m}}</option>
        </select>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'

export default {
    name: 'CarrotTime24Select',
    props: {
        modelValue: {
            type: [Number, String],
            default: "00:00",
        },
        minRange : {
            type: Number,
            default: 30
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {

        const comp = reactive({
            hour: "",
            minute: "",
            ctime: "",
            optHours: [],
            optMinutes : [],
            init: () => {
                if(props.modelValue.length==5) {
                    let val = props.modelValue.split(":");
                    if(val.length==2) {
                        comp.hour = val[0];
                        comp.minute = val[1];
                    }
                }
            },
            change: () => {
                emit('update:modelValue', comp.hour + ":" + comp.minute);
            }
        });

        onMounted(() => {
            // Mounted
            for(let i=0;i<24;i++) {
                comp.optHours.push((i<10?"0" + i: i));
            }
            comp.optMinutes = ["00", "30"];
            if(props.minRange==10) {
                comp.optMinutes = ["00", "10", "20", "30", "40", "50"];
            }
            if(props.minRange==60) {
                comp.optMinutes = ["00"];
            }
            if(props.modelValue.length==5) {
                let val = props.modelValue.split(":");
                if(val.length==2) {
                    comp.hour = val[0];
                    comp.minute = val[1];
                }
            } else {
                comp.hour = "00";
                comp.minute = "00";
            }
        });

        watch(() => props.modelValue, (value, preValue) => {
            if(preValue!=value) {
                comp.init();
            }
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
    .inline {
        display: inline-block;
    }
</style>