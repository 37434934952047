<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="촬영실/녹음실 예약">
            - 회사 내부 사용은 회사명을 캐럿솔루션즈/캐럿글로벌/밝음으로 선택해주시기 바랍니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 촬영실/녹음실</th>
                                <td>
                                    <select class="w-200px" v-model="add.studio">
                                        <option value="">선택</option>
                                        <option value="스튜디오">캐럿솔루션즈 6F 스튜디오</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 기간</th>
                                <td>
                                    <label><input type="radio" v-model="add.dtype" @change="comp.changeDaytype" value="oneday"><span class="ml-5 mr-20"> 하루</span></label>
                                    <div class="po-relative dp-inblock w-150px h-32px">
                                        <div v-if="add.dtype=='period'" class="input-lock"></div>
                                        <CarrotDatePicker v-model.sync="add.tdate" @change="comp.changeDate" class="mr-40 inline-block"></CarrotDatePicker>
                                    </div>
                                    <label><input type="radio" v-model="add.dtype" @change="comp.changeDaytype" value="period"><span class="ml-5 mr-20"> 기간</span></label>
                                    <div class="po-relative dp-inblock w-300px h-32px">
                                        <div v-if="add.dtype=='oneday'" class="input-lock"></div>
                                        <CarrotDatePicker v-model.sync="add.sdate" class="inline-block"></CarrotDatePicker>
                                        ~ 
                                        <CarrotDatePicker v-model.sync="add.edate" class="inline-block"></CarrotDatePicker>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 요일</th>
                                <td>
                                    <div class="po-relative dp-inblock w-400px h-32px">
                                        <div v-if="add.dtype=='oneday'" class="input-lock"></div>
                                        <label v-for="d in comp.dayList" :key="d"><input type="checkbox" v-model="add.days" :value="d"><span class="ml-5 mr-20"> {{ d }}</span></label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 시간</th>
                                <td>
                                    <CarrotTime24Select v-model="add.stime"></CarrotTime24Select>
                                     ~ 
                                    <CarrotTime24Select v-model="add.etime"></CarrotTime24Select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 인원</th>
                                <td>
                                    <input type="text" v-model.number="add.count" class="w-100px txt-right">
                                    <span class="ml-5">명</span>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 과정명 (용도)</th>
                                <td>
                                    <input type="text" v-model.trim="add.title" class="w-100per" maxlength="100">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 회사명</th>
                                <td>
                                    <CarrotCompany v-model:companyname.sync="add.company_name" v-model="add.idx_company" class="w-300px"></CarrotCompany>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotTime24Select from '@/components/common/CarrotTime24Select.vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'


export default {
    layout: 'ResourceManagement',
    components: {
        CarrotTime24Select,
        CarrotDatePicker,
        CarrotCompany,
    },
    setup() {
        const router = useRouter();
        const toast = useToast()

        const add = reactive({
            studio        : "",
            dtype         : "oneday",
            tdate         : "",
            sdate         : "",
            edate         : "",
            stime         : "07:00",
            etime         : "07:00",
            days          : [],
            title         : "",
            idx_company   : 0,
            company_name  : "",

            doCancel: () => {
                router.go(-1);
            },

            doSubmit : () => {
                let params = {
                    studio        : add.studio,
                    sdate         : add.dtype=='oneday'?add.tdate:add.sdate,
                    edate         : add.dtype=='oneday'?add.tdate:add.edate,
                    sday          : add.days,
                    stime         : add.stime,
                    etime         : add.etime,
                    count         : add.count+"",
                    title         : add.title,
                    idx_company   : add.idx_company,
                    company_name  : add.company_name
                };

                if( !params.studio ){
                    toast.error("촬영실/녹음실을 선택하세요.");
                    return;
                }
                if( !params.sdate || !params.edate ){
                    toast.error("기간을 선택하세요.");
                    return;
                }
                if( params.sday.length <= 0 ){
                    toast.error("요일을 선택하세요.");
                    return;
                }
                if( !params.stime || !params.etime ){
                    toast.error("시간을 선택하세요.");
                    return;
                }
                if( params.stime >= params.etime ){
                    toast.error("시간을 확인해주세요.");
                    return;
                }
                if( !params.count || params.count.replace(/[^0-9]/gi, '') != params.count || Number(params.count) <= 0 ){
                    toast.error("입원을 정확히 입력하세요.");
                    return;
                }
                if( !params.title ){
                    toast.error("과정명(용도)을 입력하세요.");
                    return;
                }
                if( !params.idx_company && !params.company_name ){
                    toast.error("회사명을 입력하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/addStudioReservation", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title: '촬영실/녹음실 예약',
                            text : '예약하였습니다.'
                        }).then(() => {
                            router.push({
                                name : 'ResourceManagement-StudioReservationMonth'
                            });
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        const comp = reactive({
            dayList: [ '월', '화', '수', '목', '금', '토', '일' ],

            changeDaytype : () => {
                if( add.dtype == 'oneday' ){
                    add.sdate = '';
                    add.edate = '';
                } else {
                    add.tdate = '';
                }
                add.days = [];
            },

            changeDate: () => {
                add.days = [];
                let td = new Date(add.tdate);
                let day = td.getDay();
                if( day == 0 ) add.days.push(comp.dayList[6])
                else add.days.push(comp.dayList[day-1])
            }
        });

        onMounted(() => {
            // Mounted
            let ss = sessionStorage.getItem('StudioReservationAdd');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.today ){
                    add.tdate = ss.today;
                    comp.changeDate();
                }
                if( ss.time ){
                    add.stime = ss.time;
                    add.etime = ss.time;
                }
                if( ss.studio ){
                    add.studio = ss.studio;
                }
            }
        });

        return {add, comp};
    }
}
</script>

<style lang="scss" scoped>
.input-lock {
    position:absolute; top:0; left:0; width:100%; height:100%; z-index:2; background-color:rgba(0,0,0,0.001);
}
</style>